import React, { useContext, useState } from 'react';
import { get } from '../../../../hooks/request/request';
import { Modal } from '@planview/pv-uikit';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import { ButtonGhost } from '../../../../components/common/button/Button';
import { HBox } from '../../../../components/common/Layout';
import useImpersonateUserAction from '../../organizationInfo/hooks/useImpersonateUserAction';
import { UserContext } from '../../../../context';
import { AppContext } from '../../../../context';
import { defineMessages, useIntl } from 'react-intl';
import useSetGroupsAction from '../../../admin/users/hooks/useSetGroupsAction';
import { noOp } from '../../../../types';
import { Input } from '@planview/pv-form';

const messages = defineMessages({
	searchLabel: {
		id: 'pvadmin.org.findusers.search',
		description: 'Placeholder for search input to find user',
		defaultMessage: 'ID or email',
	},
});

const DetailRow = styled.div`
	${text.regular};
	padding: 0 ${spacingPx.small} ${spacingPx.xsmall} ${spacingPx.small};
`;

const SearchTextInput = styled(Input)`
	width: 250px;
`;

const StyledHbox = styled(HBox)`
	gap: ${spacingPx.small};
`;

const hasAccessToCustomer = (customer, userContext) => {
	if (customer.id === 'PLANVIEW') {
		return userContext.hasSuperAdminAccess();
	}
	return true;
};

const getOrgUrl = (customer) => {
	if (customer.id === 'PLANVIEW') {
		return `/admin/users`;
	}
	return `/pvadmin/orgs/${customer.id}`;
};

const hasAccessToImpersonate = (customer) => customer.id !== 'PLANVIEW';

const FindUserDialog = ({ impersonateAction, setGroupsAction, onClose }) => {
	const [searchText, setSearchText] = useState('');
	const [foundUsers, setFoundUsers] = useState();
	const [displayMessage, setDisplayMessage] = useState();
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);
	const { enableUsernames } = appContext.featureFlags;
	const intl = useIntl();

	const isValid = () => searchText.length > 0;

	const lookupUsers = async () => {
		if (isValid()) {
			setDisplayMessage(null);
			setFoundUsers(null);
			let users;
			if (searchText.includes('@')) {
				const searchTextEncoded = encodeURIComponent(searchText);
				const result = await get(
					`/io/v1/admin/user/byEmails?emails=${searchTextEncoded}`,
				);
				users = result.users;
			} else {
				const result = await get(`/io/v1/admin/user/${searchText}`);
				users = result.users;
			}
			if (users?.length) {
				setFoundUsers(users);
			} else {
				setDisplayMessage('User not found.');
			}
		}
	};

	const handleOnKeyDown = (event) => {
		if (
			event.key === 'Enter' ||
			(event.key === 'NumpadEnter' && isValid())
		) {
			event.preventDefault();
			event.stopPropagation();
			lookupUsers();
		}
	};

	return (
		<Modal
			key="findUserDialog"
			initialFocusId="search-input"
			confirmText="Close"
			headerText="Find user"
			onConfirm={onClose}
			onCancel={onClose}
		>
			<StyledHbox>
				<SearchTextInput
					id="search-input"
					onChange={(value) => setSearchText(value)}
					label={intl.formatMessage(messages.searchLabel)}
					onKeyDown={handleOnKeyDown}
				/>
				<ButtonGhost
					message="Find"
					onClick={lookupUsers}
					disabled={!isValid()}
				/>
			</StyledHbox>
			{displayMessage}
			{foundUsers?.map((user, idx) => {
				const {
					firstName,
					lastName,
					email,
					username,
					id,
					isActive,
					isDeactivated,
					customer,
				} = user;
				const statusText = isDeactivated
					? 'Deactivated'
					: isActive
						? 'Active'
						: 'Pending';
				return (
					<div data-testid="user-details" key={idx}>
						{idx > 0 && <hr />}
						<DetailRow>
							User:&nbsp;{firstName} {lastName} {email} ({id})
						</DetailRow>
						{enableUsernames && (
							<DetailRow>Username:&nbsp;{username}</DetailRow>
						)}
						<DetailRow>
							Customer:&nbsp;
							{hasAccessToCustomer(customer, userContext) ? (
								<a href={getOrgUrl(customer)}>
									{customer.title}
								</a>
							) : (
								customer.title
							)}
							&nbsp; ({customer.id})
						</DetailRow>
						<DetailRow>
							Region:&nbsp;{customer.regulatoryRegion}
							,&nbsp; User status:&nbsp;
							{statusText}
						</DetailRow>
						<StyledHbox>
							{hasAccessToImpersonate(customer) && (
								<ButtonGhost
									message="Impersonate user"
									disabled={!isActive}
									onClick={() =>
										impersonateAction.activateFn(user)
									}
								/>
							)}
							<ButtonGhost
								message="Set groups"
								onClick={() =>
									setGroupsAction.activateFn([user])
								}
							/>
						</StyledHbox>
					</div>
				);
			})}
		</Modal>
	);
};

const useFindUserAction = () => {
	const [showDialog, setShowDialog] = useState(false);
	const { impersonateAction, impersonateModal } = useImpersonateUserAction();
	const { setGroupsAction, setGroupsModal } = useSetGroupsAction({
		refresh: noOp,
	});

	const findUserAction = {
		text: 'Find user',
		onActivate: () => {
			setShowDialog(true);
		},
	};

	const findUserModal = showDialog && (
		<FindUserDialog
			key="find-user-dialog"
			impersonateAction={impersonateAction}
			setGroupsAction={setGroupsAction}
			onClose={() => setShowDialog(false)}
		/>
	);

	const modals = [findUserModal, impersonateModal, setGroupsModal].filter(
		Boolean,
	);
	return { findUserAction, modals };
};

export default useFindUserAction;
